'use client';

import React from 'react';
import { Button } from '@/lib/components/ui/button';
import Modal from './Modal';

interface LogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LogoutModal: React.FC<LogoutModalProps> = ({ isOpen, onClose }) => {
  const handleSignIn = async () => {
    onClose();
    window.location.reload();
  };

  return (
    <Modal isOpen={isOpen} showCloseButton={false}>
      <div className='flex flex-col items-center justify-center gap-5 p-4'>
        <h2 className='text-lg font-bold text-center text-neutral-text-primary'>Session Expired</h2>
        <p className='text-center text-sm font-medium text-neutral-text-primary'>
          Your session has expired. Please sign in again to continue.
        </p>
        <Button onClick={() => handleSignIn()}>Sign In</Button>
      </div>
    </Modal>
  );
};
