import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-sm text-bold ring-offset-background transition-colors h-fit focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-100',
  {
    variants: {
      variant: {
        default:
          'border border-button-primary-bg text-button-primary-txt bg-button-primary-bg hover:bg-button-primary-hover hover:border-button-primary-hover active:bg-button-primary-active active:border-button-primary-active disabled:bg-button-primary-disabled disabled:border-button-primary-disabled disabled:text-button-primary-disabled-txt',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        danger: 'bg-red-500 text-button-primary-txt hover:bg-red-600',
        warning: 'bg-yellow-500 text-button-primary-txt hover:bg-yellow-600',
        outline:
          'border border-button-primary-outline-default text-button-primary-outline-default bg-button-primary-outline-bg hover:border-button-primary-outline-hover hover:text-button-primary-outline-hover active:border-button-primary-outline-active active:text-button-primary-outline-active disabled:border-button-primary-outline-disabled disabled:text-button-primary-outline-disabled',
        minimal:
          'border border-transparent text-button-primary-outline-default bg-transparent hover:text-button-primary-outline-hover active:text-button-primary-outline-active disabled:text-button-primary-outline-disabled',
        secondary:
          'border border-button-secondary-bg text-button-secondary-txt bg-button-secondary-bg hover:bg-button-secondary-hover hover:border-button-secondary-hover active:bg-button-secondary-active active:border-button-secondary-active disabled:bg-button-secondary-disabled disabled:border-button-secondary-disabled disabled:text-button-secondary-disabled-txt',
        secondaryOutline:
          'border border-button-secondary-outline-default text-button-secondary-outline-default bg-button-secondary-outline-bg hover:border-button-secondary-outline-hover hover:text-button-secondary-outline-hover active:border-button-secondary-outline-active active:text-button-secondary-outline-active disabled:border-button-secondary-outline-disabled disabled:text-button-secondary-outline-disabled',
        secondaryMinimal:
          'border border-transparent text-button-secondary-outline-default bg-transparent hover:text-button-secondary-outline-hover active:text-button-secondary-outline-active disabled:text-button-secondary-outline-disabled',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        // link: 'text-primary underline-offset-4 hover:underline',
        link: 'text-primary',
      },
      size: {
        default: 'control rounded-md',
        // xxs: 'control-xxs',
        xs: 'control-xs',
        sm: 'control-sm',
        md: 'control-md rounded-lg',
        lg: 'control-lg rounded-lg',
        // icon: 'h-10 w-10',
        // input: 'min-h-10 px-3 py-2',
        // 'icon-sm': 'h-6 w-6 p-1',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    props.type = props.type || 'button';
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
