'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import React, { ReactNode } from 'react';
import { Drawer, DrawerContent, DrawerTitle } from '@/lib/components/ui/drawer';
import { Dialog, DialogContent, DialogTitle } from '@/lib/components/ui/dialog';
import { cn } from '../utils';

export interface PopoverProps {
  displayAs?: 'MODAL' | 'SLIDEOUT';
  isOpen?: boolean;
  closeRoute?: string;
  direction?: 'top' | 'bottom' | 'left' | 'right';
  children?: ReactNode;
  keepQueryParams?: boolean;
  showCloseButton?: boolean;
  className?: string;
  onClose?: () => void;
}

export default function Popover({
  isOpen,
  closeRoute,
  displayAs = 'SLIDEOUT',
  direction,
  children,
  keepQueryParams = true,
  showCloseButton = true,
  className,
  onClose,
}: PopoverProps) {
  direction = direction ?? 'right';
  const router = useRouter();
  const searchParams = useSearchParams();
  const queryParams = new URLSearchParams(searchParams);
  const getQueryParams = () => (queryParams.toString() ? `?${queryParams.toString()}` : '');

  const handleOpenChange = (newIsOpen: boolean) => {
    if (closeRoute && !newIsOpen) {
      router.push(keepQueryParams ? `${closeRoute}${getQueryParams()}` : closeRoute);
    }
    if (onClose && !newIsOpen) {
      onClose();
    }
  };

  return displayAs === 'SLIDEOUT' ? (
    <Drawer open={isOpen} onOpenChange={handleOpenChange} direction={direction}>
      <DrawerTitle />
      <DrawerContent
        className={cn('h-screen top-0 right-0 left-auto mt-0 p-8 min-w-150 focus-visible:outline-0', className)}
        data-vaul-no-drag
        style={{
          width: '40vw',
          borderTopRightRadius: direction === 'right' ? '0' : '',
          borderBottomRightRadius: direction === 'right' ? '0' : '',
        }}
      >
        {children}
      </DrawerContent>
    </Drawer>
  ) : (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTitle />
      <DialogContent showCloseButton={showCloseButton} className={className}>{children}</DialogContent>
    </Dialog>
  );
}
