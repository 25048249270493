'use client';

import React, { useState, useEffect, useRef, ReactNode, useCallback } from 'react';
import { useSession } from 'next-auth/react';
import { LogoutModal } from '../LogoutModal';
import { logout } from '@/app/actions/auth/logout';

const INACTIVITY_DEV_MODE = 8 * 60 * 60 * 1000;
const INACTIVITY_TIME_USERS = 30 * 60 * 1000;
const DEFAULT_EVENTS = [
  'mousemove',
  'mousedown',
  'keydown',
  'scroll',
  'touchstart',
  'touchmove',
  'touchend',
  'visibilitychange',
  'focus',
] as const;

type EventType = (typeof DEFAULT_EVENTS)[number];

const Inactivity = ({ children }: Readonly<{ children: ReactNode }>) => {
  const { data: session } = useSession();
  const userEmailRef = useRef<string | undefined | null>();
  const inactivityTimerRef = useRef<number | null>(null);

  const [openLogoutModal, setOpenLogoutModal] = useState<boolean>(false);

  const INACTIVITY_TIME = process.env.NODE_ENV === 'development' ? INACTIVITY_DEV_MODE : INACTIVITY_TIME_USERS;

  const getLastClosedTimeKey = useCallback(() => `last-closed-time-${userEmailRef.current}`, []);
  const clearTimer = () => inactivityTimerRef.current && clearTimeout(inactivityTimerRef.current);

  const handleInactivity = async () => {
    if (userEmailRef.current) {
      localStorage.removeItem(getLastClosedTimeKey());
      await logout();
      userEmailRef.current = null;
      setOpenLogoutModal(true);
    }
  };

  const resetInactivityTimer = () => {
    localStorage.removeItem(getLastClosedTimeKey());
    clearTimer();
    inactivityTimerRef.current = window.setTimeout(handleInactivity, INACTIVITY_TIME);
  };

  const validateInactivity = useCallback(async () => {
    const lastClosedTime = localStorage.getItem(getLastClosedTimeKey());
    if (lastClosedTime && Date.now() - Number(lastClosedTime) > INACTIVITY_TIME) {
      handleInactivity();
    } else {
      resetInactivityTimer();
    }
  }, [getLastClosedTimeKey, INACTIVITY_TIME]);

  const handleBeforeUnload = useCallback(() => {
    if (userEmailRef.current) {
      localStorage.setItem(getLastClosedTimeKey(), Date.now().toString());
    }
  }, [getLastClosedTimeKey]);

  const handleVisibilityChange = useCallback(() => {
    document.hidden ? handleBeforeUnload() : validateInactivity();
  }, [handleBeforeUnload, validateInactivity]);

  useEffect(() => {
    const setCurrentUserEmail = () => {
      userEmailRef.current = session?.user?.email;
      validateInactivity();
      DEFAULT_EVENTS.forEach((event: EventType) => {
        const handler = event === 'visibilitychange' ? handleVisibilityChange : resetInactivityTimer;
        const target = event === 'visibilitychange' ? document : window;
        target.addEventListener(event, handler);
      });
      window.addEventListener('beforeunload', handleBeforeUnload);
    };

    if (session) setCurrentUserEmail();

    return () => {
      DEFAULT_EVENTS.forEach((event) => {
        const handler = event === 'visibilitychange' ? handleVisibilityChange : resetInactivityTimer;
        const target = event === 'visibilitychange' ? document : window;
        target.removeEventListener(event, handler);
      });
      clearTimer();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [session, validateInactivity, handleVisibilityChange, handleBeforeUnload]);

  return (
    <>
      {children}
      <LogoutModal isOpen={openLogoutModal} onClose={() => setOpenLogoutModal(false)} />
    </>
  );
};

export default Inactivity;
